<template>
  <div class="px-5">
    <Toast :show="alert.show" :msg="alert.msg" :color="alert.color" position="top" />
    <div class="align-center">
      <v-row class="mt-1">
        <v-col cols="12">
          <div>
            <div class="d-flex justify-space-between mt-2">
              <div class="mb-5">
                <router-link to="/lhp/dashboard">
                  <v-btn class="rounded-lg purple lighten-1 white--text">
                    <v-icon left class="p-4">mdi-arrow-left-circle</v-icon>
                    Kembali
                  </v-btn>
                </router-link>
              </div>
              <div class="mb-5">
                <v-btn class="rounded-lg" @click="sheet = true">
                  <v-icon left> mdi-plus-circle </v-icon>
                  Tambah
                </v-btn>
              </div>
            </div>
            <v-row class="mb-5 pb-5">
              <v-col col="12">
                <ListPenilaian />
              </v-col>
            </v-row>
            <!-- Bottom Sheet -->
            <v-bottom-sheet v-model="sheet" max-width="480px" inset persistent>
              <v-sheet class="custom-sheet pa-1 elevation-0">
                <!-- Header -->
                <div class="sheet-header">
                  <v-btn class="ma-2" outlined x-small fab color="grey" @click="(sheet = false), clearAll()">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                  <h3 class="form-title mt-4">Form Penilaian</h3>
                </div>
                <!-- End Header -->

                <v-divider class="mt-2" />

                <!-- Isi konten -->
                <div class="sheet-content">
                  <v-form @submit.prevent="doSubmit">
                    <!-- Pilih Petugas, Pilih Kumpulan, Info Jumlah Anggota -->
                    <div>
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-autocomplete dense autocomplete="off" outlined :items="opt.petugas" item-value="id" item-text="nama" v-model="form.data.petugas" label="Petugas" required :rules="[(v) => !!v || 'Petugas is required']" />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <!-- Pilih Kumpulan -->
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-autocomplete
                            dense
                            autocomplete="off"
                            outlined
                            :items="opt.lansatList"
                            item-value="id"
                            item-text="nama"
                            v-model="form.data.lansat"
                            label="Lansat"
                            :disabled="!form.data.petugas"
                            required
                            :rules="[(v) => !!v || 'Lansat is required']"
                            @change="updateJAnggota"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />

                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field type="number" dense outlined v-model="form.data.j_anggota" label="Jumlah Anggota" :readonly="true" :disabled="!form.data.lansat" />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    <!-- INPUT EKANISME PENGERJAAN KUMPULAN PUSAT -->
                    <div>
                      <v-row class="mt-2 text-right">
                        <v-col cols="12">
                          <span class="text-caption font-weight-bold"> MEKANISME PENGERJAAN KUMPULAN PUSAT </span> <br />
                          <span class="text-caption font-weight-bold">(Nilai 1 - 10)</span>
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.pembukaan"
                            label="pembukaan"
                            required
                            :rules="[(v) => !!v || 'pembukaan is required (1-10)']"
                            @input="validateInput('pembukaan', $event)"
                            @keypress="validateKeypress($event, 'pembukaan')"
                            @blur="handleBlur('pembukaan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.pengumpulan_angsuran"
                            label="Pengumpulan Angsuran"
                            required
                            :rules="[(v) => !!v || 'Pengumpulan Angsuran is required (1-10)']"
                            @input="validateInput('pengumpulan_angsuran', $event)"
                            @keypress="validateKeypress($event, 'pengumpulan_angsuran')"
                            @blur="handleBlur('pengumpulan_angsuran')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.pengajuan_pembiayaan"
                            label="Pengajuan Pembiayaan"
                            required
                            :rules="[(v) => !!v || 'Pengajuan Pembiayaan is required (1-10)']"
                            @input="validateInput('pengajuan_pembiayaan', $event)"
                            @keypress="validateKeypress($event, 'pengajuan_pembiayaan')"
                            @blur="handleBlur('pengajuan_pembiayaan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.pencairan_pembiayaan"
                            label="Pencairan Pembiayaan"
                            required
                            :rules="[(v) => !!v || 'Pencairan Pembiayaan is required (1-10)']"
                            @input="validateInput('pencairan_pembiayaan', $event)"
                            @keypress="validateKeypress($event, 'pencairan_pembiayaan')"
                            @blur="handleBlur('pencairan_pembiayaan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.penarikan_simpanan"
                            label="Penarikan Simpanan"
                            required
                            :rules="[(v) => !!v || 'Penarikan Simpanan is required (1-10)']"
                            @input="validateInput('penarikan_simpanan', $event)"
                            @keypress="validateKeypress($event, 'penarikan_simpanan')"
                            @blur="handleBlur('penarikan_simpanan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.penyetoran_simpanan"
                            label="Penyetoran Simpanan"
                            required
                            :rules="[(v) => !!v || 'Penyetoran Simpanan is required (1-10)']"
                            @input="validateInput('penyetoran_simpanan', $event)"
                            @keypress="validateKeypress($event, 'penyetoran_simpanan')"
                            @blur="handleBlur('penyetoran_simpanan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.penutupan_materi_pendampingan"
                            label="Penutupan & Materi Pendampingan"
                            required
                            :rules="[(v) => !!v || 'Penutupan & Materi Pendampingan is required (1-10)']"
                            @input="validateInput('penutupan_materi_pendampingan', $event)"
                            @keypress="validateKeypress($event, 'penutupan_materi_pendampingan')"
                            @blur="handleBlur('penutupan_materi_pendampingan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.urutan_prosedur_transaksi"
                            label="Urutan Prosedur Transaksi"
                            required
                            :rules="[(v) => !!v || 'Urutan Prosedur Transaksi is required (1-10)']"
                            @input="validateInput('urutan_prosedur_transaksi', $event)"
                            @keypress="validateKeypress($event, 'urutan_prosedur_transaksi')"
                            @blur="handleBlur('urutan_prosedur_transaksi')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- INTERPERSONAL & MANAGEMENT -->
                    <div>
                      <v-row class="mt-2 text-right">
                        <v-col cols="12">
                          <span class="text-caption font-weight-bold"> INTERPERSONAL & MANAGEMENT </span> <br />
                          <span class="text-caption font-weight-bold">(Nilai 1 - 10)</span>
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.penampilan_kerapihan"
                            label="Penampilan Kerapihan"
                            required
                            :rules="[(v) => !!v || 'Penampilan & Kerapihan is required (1-10)']"
                            @input="validateInput('penampilan_kerapihan', $event)"
                            @keypress="validateKeypress($event, 'penampilan_kerapihan')"
                            @blur="handleBlur('penampilan_kerapihan')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.komunikasi"
                            label="Komunikasi"
                            required
                            :rules="[(v) => !!v || 'Komunikasi is required (1-10)']"
                            @input="validateInput('komunikasi', $event)"
                            @keypress="validateKeypress($event, 'komunikasi')"
                            @blur="handleBlur('komunikasi')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.cara_penyampaian"
                            label="Cara Penyampaian"
                            required
                            :rules="[(v) => !!v || 'Cara Penyampaian is required (1-10)']"
                            @input="validateInput('cara_penyampaian', $event)"
                            @keypress="validateKeypress($event, 'cara_penyampaian')"
                            @blur="handleBlur('cara_penyampaian')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.motivasi_sosialisasi"
                            label="Motivasi & Sosialisasi"
                            required
                            :rules="[(v) => !!v || 'Motivasi & Sosialisasi is required (1-10)']"
                            @input="validateInput('motivasi_sosialisasi', $event)"
                            @keypress="validateKeypress($event, 'motivasi_sosialisasi')"
                            @blur="handleBlur('motivasi_sosialisasi')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.ketepatan_waktu"
                            label="Ketepatan Waktu"
                            required
                            :rules="[(v) => !!v || 'Ketepatan Waktu is required (1-10)']"
                            @input="validateInput('ketepatan_waktu', $event)"
                            @keypress="validateKeypress($event, 'ketepatan_waktu')"
                            @blur="handleBlur('ketepatan_waktu')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.kerapihan_lansat"
                            label="Kerapihan Lansat"
                            required
                            :rules="[(v) => !!v || 'Kerapihan Lansat is required (1-10)']"
                            @input="validateInput('kerapihan_lansat', $event)"
                            @keypress="validateKeypress($event, 'kerapihan_lansat')"
                            @blur="handleBlur('kerapihan_lansat')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            dense
                            :disabled="!form.data.lansat"
                            outlined
                            v-model="form.data.penilaian_anggota"
                            label="Penilaian Anggota"
                            required
                            :rules="[(v) => !!v || 'Penilaian Anggota is required (1-10)']"
                            @input="validateInput('penilaian_anggota', $event)"
                            @keypress="validateKeypress($event, 'penilaian_anggota')"
                            @blur="handleBlur('penilaian_anggota')"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>
                    <!-- Catatan -->
                    <div>
                      <v-row class="mt-2 align-center">
                        <v-col cols="12">
                          <v-textarea dense :disabled="!form.data.lansat" autocomplete="off" rows="3" outlined v-model="form.data.catatan" label="Catatan" required :rules="[(v) => !!v || 'Catatan is required']" />
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Kamera -->
                    <div>
                      <v-row>
                        <v-col cols="12">
                          <div class="web-camera-container">
                            <div class="camera-button">
                              <v-btn small @click="toggleCamera" :disabled="!form.data.lansat">
                                <span v-if="!isCameraOpen"> <v-icon left>mdi-camera</v-icon> Open Camera</span>
                                <span v-else> <v-icon left>mdi-camera-off</v-icon> Close Camera </span>
                              </v-btn>
                            </div>
                            <div v-show="isCameraOpen && isLoading" class="camera-loading">
                              <ul class="loader-circle">
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                            <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ flash: isShotPhoto }">
                              <video v-show="!isPhotoTaken" ref="camera" :class="isFrontCamera ? 'front-camera' : 'back-camera'" :facingMode="isFrontCamera ? 'user' : 'environment'" autoplay></video>
                              <canvas :class="isFrontCamera ? 'front-camera' : 'back-camera'" :facingMode="isFrontCamera ? 'user' : 'environment'" v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                              <div class="camera-shoot d-flex justify-center align-center" style="width: 100%; position: relative">
                                <!-- Switch Camera (di kiri) -->
                                <v-btn @click="switchCamera" fab small style="position: absolute; left: 0">
                                  <v-icon color="white">mdi-camera-flip</v-icon>
                                </v-btn>

                                <!-- Take Photo (di tengah) -->
                                <v-btn @click="takePhoto" fab large>
                                  <v-icon>{{ form.data.selfi != null ? "mdi-refresh-circle" : "mdi-camera-iris" }}</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider />
                    </div>

                    <!-- Tombol Aksi -->
                    <v-row>
                      <v-col cols="12" class="d-flex" style="padding: 20px 0px !important">
                        <v-col cols="6">
                          <v-btn outlined color="orange" depressed @click="(sheet = false), clearAll()" :disabled="form.loading" style="width: 100%"> Cancel </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn depressed :loading="loadingBtn" type="submit" :disabled="(loadingBtn, isFormIncomplete)" color="success" @click="loader = 'loadingBtn'" style="width: 100%">
                            Kirim
                            <template v-slot:loader>
                              <span>Sedang Mengirim...</span>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-sheet>
            </v-bottom-sheet>
            <!-- End Bottom Sheet -->
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import ListPenilaian from "./List/ListPenilaian.vue";
import dataDummy from "../../services/dataDummy";
export default {
  name: "PenilaianDashboard",
  components: {
    Toast,
    ListPenilaian,
  },
  data() {
    return {
      isFrontCamera: true,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      loader: null,
      dialog: false,
      detail: null,
      loading: false,
      search: "",
      sheet: false,
      loadingBtn: false,
      form: {
        data: {
          petugas: null,
          lansat: null,
          j_anggota: 0,
          //   Mekanisme Pengerjaan Kumpulan Pusat
          pembukaan: 1,
          pengumpulan_angsuran: 1,
          pengajuan_pembiayaan: 1,
          pencairan_pembiayaan: 1,
          penarikan_simpanan: 1,
          penyetoran_simpanan: 1,
          penutupan_materi_pendampingan: 1,
          urutan_prosedur_transaksi: 1,
          //   Interpersonal & Management
          penampilan_kerapihan: 1,
          komunikasi: 1,
          cara_penyampaian: 1,
          motivasi_sosialisasi: 1,
          ketepatan_waktu: 1,
          kerapihan_lansat: 1,
          penilaian_anggota: 1,

          catatan: null,
          selfi: null,
        },
      },
      opt: {
        petugas: [],
        lansatList: [],
        lansat: [],
      },
      alert: {
        show: false,
        msg: "",
        color: "primary",
      },
    };
  },
  async created() {
    await this.loadPetugas();
    await this.loadLansat();
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // setTimeout(() => (this[l] = false), 1000);

      this.loader = null;
    },
  },
  methods: {
    async loadPetugas() {
      this.opt.petugas = await dataDummy.getPetugas();
    },
    async loadLansat() {
      this.opt.lansatList = await dataDummy.getLansat();
    },

    // Update jumlah anggota saat lansat dipilih
    updateJAnggota() {
      const selectedLansat = this.opt.lansatList.find((lansat) => lansat.id === this.form.data.lansat);
      this.form.data.j_anggota = selectedLansat ? selectedLansat.j_anggota : "";
    },
    validateKeypress(event, field) {
      let charCode = event.which ? event.which : event.keyCode;
      let charStr = String.fromCharCode(charCode);

      // Cegah input jika bukan angka
      if (!/^\d$/.test(charStr)) {
        event.preventDefault();
        return;
      }

      // Simulasi nilai baru jika karakter ditambahkan
      let newValue = this.form.data[field] ? this.form.data[field].toString() + charStr : charStr;

      // Cegah angka di luar rentang 1-10
      if (parseInt(newValue) > 10 || parseInt(newValue) < 1) {
        event.preventDefault();
      }
    },
    validateInput(field, event) {
      // Hapus karakter non-angka dan batasi rentang 1-10
      let value = event.target.value.replace(/\D/g, "");
      value = Math.max(1, Math.min(10, parseInt(value) || 1)); // Default ke 1 jika kosong
      this.$set(this.form.data, field, value);
    },
    handleBlur(field) {
      // Jika input kosong setelah blur, set default ke 1
      if (!this.form.data[field]) {
        this.$set(this.form.data, field, 1);
      }
    },
    // CAMER SELFIR
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: this.isFrontCamera ? "user" : "environment", // Menggunakan "user" untuk kamera depan, "environment" untuk kamera belakang
        },
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    stopCameraStream() {
      if (this.$refs.camera && this.$refs.camera.srcObject) {
        this.$refs.camera.srcObject.getTracks().forEach((track) => track.stop());
        this.$refs.camera.srcObject = null;
      }
    },

    previewImage(event) {
      let theImg = null;
      let vm = this;
      const foto = this.$refs["fm-foto"];
      let reader = new FileReader();
      theImg = event.target.files[0];
      reader.readAsDataURL(theImg);
      reader.onload = function () {
        vm.form.data.selfi = reader.result;
        foto.type = "text";
        foto.type = "file";
      };
      reader.onerror = function () {
        vm.form.data.selfi = null;
        foto.type = "text";
        foto.type = "file";
      };
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, 50);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      if (this.isPhotoTaken) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.camera;
        const context = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.form.data.selfi = canvas.toDataURL();
      } else {
        this.form.data.selfi = null;
      }
    },
    switchCamera() {
      this.isFrontCamera = !this.isFrontCamera;
      this.form.data.selfi = null;
      this.isPhotoTaken = false;
      this.stopCameraStream();
      this.createCameraElement();
    },
    // END CAMERA SELFIE
    // FORM SUBMIT
    async doSubmit() {
      this.loadingBtn = true; // Aktifkan loading

      try {
        let payload = new FormData();

        // Pastikan `this.form.data` bukan null/undefined
        if (this.form && this.form.data) {
          for (let key in this.form.data) {
            if (this.form.data[key] !== null && this.form.data[key] !== undefined) {
              payload.append(key, this.form.data[key]);
            }
          }
        }

        for (let [key, value] of payload.entries()) {
          console.log(`${key}: ${value}`);
        }

        // Simulasi API request (gunakan `await axios.post(...)` jika menggunakan Axios)
        await new Promise((resolve) => setTimeout(resolve, 2000));

        const response = { status: 200, message: "Data berhasil dikirim!" };
        if (response.status == 200) {
          this.alert = {
            show: true,
            msg: response.message,
            color: "success",
          };

          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 3000);
        }
        console.log("Form berhasil dikirim!");
      } catch (error) {
        this.alert = {
          show: true,
          msg: response.message,
          color: "error",
        };
        console.error("Terjadi kesalahan:", error);
      } finally {
        this.loadingBtn = false; // Matikan loading setelah selesai
      }
    },

    clearAll() {
      this.form.data.petugas = null;
      this.form.data.lansat = null;
      this.form.data.j_anggota = 1;
      this.form.data.pembukaan = 1;
      this.form.data.pengumpulan_angsuran = 1;
      this.form.data.pengajuan_pembiayaan = 1;
      this.form.data.pencairan_pembiayaan = 1;
      this.form.data.penarikan_simpanan = 1;
      this.form.data.penyetoran_simpanan = 1;
      this.form.data.penutupan_materi_pendampingan = 1;
      this.form.data.urutan_prosedur_transaksi = 1;
      this.form.data.penampilan_kerapihan = 1;
      this.form.data.komunikasi = 1;
      this.form.data.cara_penyampaian = 1;
      this.form.data.motivasi_sosialisasi = 1;
      this.form.data.ketepatan_waktu = 1;
      this.form.data.kerapihan_lansat = 1;
      this.form.data.penilaian_anggota = 1;
      this.form.data.catatan = null;
      this.form.data.selfi = null;
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.sheet = false;
      this.stopCameraStream();
    },
  },
  computed: {
    isFormIncomplete() {
      const formData = this.form.data;

      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          const value = formData[key];

          if (value === null || (typeof value === "object" && Object.values(value).includes(null)) || "") {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-sheet {
  max-width: 480px;
  margin: auto;
  text-align: center;
  border-radius: 30px 30px 0 0;
  padding: 16px;
  position: relative;
}

.sheet-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  -ms-overflow-style: none; /* Untuk Internet Explorer dan Edge */
  scrollbar-width: none; /* Untuk Firefox */
}

video.back-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: none;
}
canvas.back-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: none;
}
video.front-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
canvas.front-camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.selfi {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(119, 3, 123);
  border-radius: 4px;
  width: 100%;

  .camera-button {
    margin: 2rem;
    font-size: 12px;
    button {
      padding: 5px 20px 5px 20px;
      border-radius: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      .icon {
        font-size: 14px;
      }
    }
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: 300px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;
    width: 100%;
    button {
      display: flex;
      padding: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      border-radius: 100%;
      .icon {
        font-size: 30px;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -3rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background-color: rgb(119, 3, 123);
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  //Custom loader
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
