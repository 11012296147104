<template>
  <div>
    <v-card class="pa-5 rounded-lg text-center text-caption" v-if="loading">
      <span>Sedang Memuat Data....</span>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Silahkan Ketik Untuk Mencari..." single-line hide-details></v-text-field>
      </v-card-title>
      <div class="table-container pa-4">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          class="small-text"
          mobile-breakpoint="220"
          :headers="headers"
          :items="listPenilaian"
          :search="search"
          item-key="name"
          :footer-props="{
            'items-per-page-options': [5, 10, 25],
            'show-current-page': true,
          }"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" @click="$router.push(`/penilaian/detail/${item.id}`)" style="cursor: pointer">
                <td>{{ item.name }}</td>
                <td>{{ item.supervisor }}</td>
                <td>{{ item.lansat }}</td>
                <td>{{ formatTanggal(item.tanggal) }}</td>
                <td class="text-center">{{ item.score }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: null,
      loading: false,
      search: "",
      headers: [
        { text: "Nama Petugas", align: "start", sortable: true, value: "name" },
        { text: "Supervisor", value: "supervisor" },
        { text: "Lansat", value: "lansat" },
        { text: "Tanggal", value: "tanggal" },
        { text: "Score", value: "score" },
      ],
      listPenilaian: [],
    };
  },
  methods: {
    showDetail(item) {
      this.detail = item;
    },
    formatTanggal(date) {
      if (!date) return "";
      const tanggal = new Date(date);
      return tanggal.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    fetchData() {
      this.loading = true;
      // Simulasi pengambilan data
      setTimeout(() => {
        this.listPenilaian = [
          {
            id: 1,
            name: "Petugas 1",
            supervisor: "Supervisor 1",
            lansat: "001-Melati",
            tanggal: "2024-02-01",
            score: "77",
          },
          {
            id: 2,
            name: "Petugas 2",
            supervisor: "Supervisor 2",
            lansat: "002-Mawar",
            tanggal: "2024-02-02",
            score: "85",
          },
          {
            id: 3,
            name: "Petugas 3",
            supervisor: "Supervisor 3",
            lansat: "003-Kenanga",
            tanggal: "2024-02-03",
            score: "90",
          },
          {
            id: 4,
            name: "Petugas 4",
            supervisor: "Supervisor 4",
            lansat: "004-Anggrek",
            tanggal: "2024-02-04",
            score: "70",
          },
          {
            id: 5,
            name: "Petugas 5",
            supervisor: "Supervisor 5",
            lansat: "005-Sakura",
            tanggal: "2024-02-05",
            score: "88",
          },
          {
            id: 6,
            name: "Petugas 6",
            supervisor: "Supervisor 6",
            lansat: "006-Tulip",
            tanggal: "2024-02-06",
            score: "92",
          },
          {
            id: 7,
            name: "Petugas 7",
            supervisor: "Supervisor 7",
            lansat: "007-Lili",
            tanggal: "2024-02-07",
            score: "75",
          },
          {
            id: 8,
            name: "Petugas 8",
            supervisor: "Supervisor 8",
            lansat: "008-Kamboja",
            tanggal: "2024-02-08",
            score: "80",
          },
          {
            id: 9,
            name: "Petugas 9",
            supervisor: "Supervisor 9",
            lansat: "009-Dahlia",
            tanggal: "2024-02-09",
            score: "95",
          },
          {
            id: 10,
            name: "Petugas 10",
            supervisor: "Supervisor 10",
            lansat: "010-Rose",
            tanggal: "2024-02-10",
            score: "78",
          },
          {
            id: 11,
            name: "Petugas 11",
            supervisor: "Supervisor 11",
            lansat: "011-Orchid",
            tanggal: "2024-02-11",
            score: "85",
          },
        ];
        this.loading = false; // Data selesai dimuat
      }, 2000);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
::v-deep(.v-data-table) {
  font-size: 11px !important;
}

::v-deep(.v-data-table th),
::v-deep(.v-data-table td) {
  font-size: 11px !important;
}
::v-deep(.v-data-footer) {
  font-size: 8px !important;
}
::v-deep(.v-data-footer__select .v-select__selections .v-select__selection--comma) {
  font-size: 8px !important;
}
::v-deep(.v-input) {
  font-size: 12px !important;
}
::v-deep(.v-label) {
  font-size: 12px !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
